<template>
  <div class="jobs">
    <div class="jobs-main">
      <h3>加入优比客思<br />开启新的篇章 打开职业发展之门</h3>
      <p>你们每一位都可以成为行业大咖，优比客思欢迎您的加入!</p>
      <el-table
        :data="tableData"
        style="width: 100%"
        :header-cell-style="headerCellStyle"
        :row-style="rowStyle"
        @row-click="handleClick"
      >
        <el-table-column prop="jobPost" label="职位" width="456" />
        <el-table-column prop="jobTypeName" label="类别" width="486" />
        <el-table-column prop="jobPlace" label="地点" width="194" />
        <template #empty>
          <PageLoading v-if="loading" style="margin: 30px" />
          <el-empty v-else description="暂无数据" :image-size="100">
            <div class="load-more refresh" @click="refresh">刷新</div>
          </el-empty>
        </template>
      </el-table>
    </div>
  </div>
  <div class="delevery">
    <h3>期待与您共事 请投递您的简历至</h3>
    <p>career@ubixai.com</p>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { IPage } from '@/service/api/company/type'
import { apiGetJobs } from '@/service/api/company'

const tableData = ref<any[]>([])
const loading = ref(false)

const page: IPage = {
  queryEntity: {},
  pageInfo: {
    orders: [
      {
        asc: false,
        column: 'id',
      },
    ],
    current: 1,
    size: 100,
  },
}

const sortJobsByType = (data: any) => {
  const sortArr = [2, 1, 7, 5, 6, 3, 4]
  // 对数组进行排序，根据jobType在sortArr中的索引
  return data.sort((a: any, b: any) => {
    const indexA = sortArr.indexOf(a.jobType)
    const indexB = sortArr.indexOf(b.jobType)
    return indexA - indexB
  })
}

async function getJobs() {
  loading.value = true
  try {
    const res = await apiGetJobs(page)
    console.log(res)
    if (res.code === 200) {
      // jobtype 1技术 2产品  3运营  4职能  5销售  6商务 7解决方案
      // 以这个顺序排序 产品-研发-解决方案-销售-商务-运营-职能
      tableData.value = sortJobsByType(res.data)
    }
  } catch (error) {
    console.error(error)
  } finally {
    setTimeout(() => {
      loading.value = false
    }, 10000)
  }
}

const handleClick = (row: any) => {
  if (row.jobInviteUrl) {
    window.open(row.jobInviteUrl, '_blank')
  }
}

const refresh = () => {
  getJobs()
}

const headerCellStyle = () => {
  return {
    'background-color': '#F5F7FA',
    height: '56px',
    'font-weight': 600,
    'font-size': '16px',
    color: 'rgba(0,0,0,0.4)',
  }
}

const rowStyle = () => {
  return {
    height: '56px',
    'font-size': '14px',
    color: '#000000',
  }
}

onMounted(() => {
  getJobs()
})
</script>

<style lang="scss" scoped>
.jobs {
  width: 100%;
  background-color: $colorWhite1;
  padding: 60px 0;
  text-align: center;

  .jobs-main {
    @include width-1440;
  }

  .refresh {
    width: 100px;
    color: $colorBlack2;
    margin-bottom: 30px;
    height: 32px;
    line-height: 32px;
    background: $colorBgLight;
    border-radius: 20px;
    font-size: $font2;
    text-align: center;
    cursor: pointer;
  }

  :deep(.el-empty__description p) {
    line-height: 16px;
  }

  h3 {
    font-size: $titleFont4;
    font-weight: $mediumWeight;
    color: $colorBlack1;
    line-height: 66px;
    margin-top: 24px;
    margin-bottom: 8px;
  }
  p {
    margin-bottom: 48px;
    height: 30px;
    font-size: $font4;
    color: $colorBlack2;
    line-height: 30px;
  }
}

.delevery {
  width: 100%;
  height: 300px;
  background: $colorBgDark;
  box-shadow: inset 0px -1px 0px 0px rgba(217, 217, 217, 0.08);
  padding-top: 79px;
  text-align: center;

  h3 {
    height: 78px;
    font-size: 56px;
    font-weight: $mediumWeight;
    color: $colorWhite1;
    line-height: 78px;
    margin-bottom: 16px;
  }
  p {
    height: 34px;
    font-size: 24px;
    color: rgba(255, 255, 255, 0.75);
    line-height: 34px;
  }
}
</style>
