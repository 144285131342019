<template>
  <div v-loading="loading" class="company-news">
    <div class="news-content">
      <h1 class="news-title">{{ newsDetail.title }}</h1>
      <!-- <h2 class="news-sub-title">{{ newsDetail.subTitle }}</h2> -->
      <h3 class="news-date">
        {{ newsDetail.releaseTime }}
        <el-popover placement="bottom" :width="195" trigger="hover">
          <template #reference>
            <!-- <span class="share"><img :src="ShareIcon" />分享</span> -->
            <!-- <span class="share"
              ><Share
                style="width: 20px; height: 20px; margin-right: 8px"
              />分享</span
            > -->
            <span class="share">
              <!-- <Share
                style="width: 20px; height: 20px; margin-right: 8px"
              /> -->
              <img class="wecaht-img" src="@/assets/svg/wechat.svg" />
              在微信上查看</span
            >
          </template>
          <canvas ref="qrCanvas"></canvas>
        </el-popover>
      </h3>
      <div class="news-html" v-html="newsDetail.content" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { apiGetNewsDetail, apiGetNewsDetailById } from '@/service/api/company'
import { useRouter, useRoute } from 'vue-router'
import { ElMessage } from 'element-plus'
import QRCode from 'qrcode'
import { Share } from '@element-plus/icons-vue'

const qrCanvas = ref(null)

const router = useRouter()
const route = useRoute()

const handleBack = () => {
  router.go(-1)
}

const loading = ref(false)
const newsDetail = ref({})

async function getNewsDetail(id) {
  loading.value = true
  try {
    const res = await apiGetNewsDetailById({ id })
    if (res.code === 200 && res.data) {
      newsDetail.value = res.data
    }
  } catch (error) {
    console.error(error)
  } finally {
    loading.value = false
  }
}

onMounted(() => {
  const id = route.query.id
  if (id) {
    getNewsDetail(+id)
    // const host = window.location.host
    QRCode.toCanvas(
      qrCanvas.value,
      `${import.meta.env.VITE_APP_HOST}/company/newsDetail?id=${id}`,
      (error) => {
        if (error) {
          console.error(error)
        }
      },
    )
  } else {
    ElMessage({
      message: '文章不存在！',
      type: 'warning',
      onClose: () => {
        handleBack()
      },
    })
  }
})
</script>

<style lang="scss" scoped>
.company-news {
  min-height: 1000px;
  padding: 60px 20px 50px;
  .news-content {
    width: 700px;
    margin: 0 auto;
    .news-title {
      font-size: 28px;
      color: #313233;
      font-weight: 400;
      line-height: 1.5;
      margin-bottom: 12px;
      font-weight: 700;
      text-align: justify;
    }
    .news-sub-title {
      font-size: 18px;
      color: #636567;
      line-height: 1.5;
      margin-bottom: 8px;
      font-weight: 400;
    }
    .news-date {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      color: #626366;
      margin-bottom: 30px;
      font-weight: 400;
      .share {
        display: flex;
        align-items: center;
        cursor: pointer;
        .wecaht-img {
          width: 20px;
          margin-right: 12px;
        }
      }
    }
    .news-html {
      word-break: break-all;
      :deep(pre) {
        max-width: 100%;
        overflow: auto;
        white-space: pre-wrap;
      }
      :deep(img) {
        height: auto !important;
      }
    }
  }
}
</style>
