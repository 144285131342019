import { post, get } from '@/service/http'
import { IPage } from './type'

const apiGetPicList = () => {
  return post(`/culture/queryList`, {})
}

const apiGetNews = (params: IPage) => {
  return post('/news/queryByCond', params)
}

const apiGetNewsDetail = (params: { id: number }) => {
  return get('/news/getById', params)
}

const apiGetNewsDetailById = (params: { id: number }) => {
  return post('/news/getNewsById', params)
}

const apiGetJobs = (params: IPage) => {
  return post('/invite/queryList', params)
}

export {
  apiGetNews,
  apiGetNewsDetail,
  apiGetJobs,
  apiGetPicList,
  apiGetNewsDetailById,
}
